<template>
  <b-modal
    ref="modalCreateBuilding"
    :visible="createBuildingActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Building`"
    ok-title="Create Building"
    centered
    body-class="pt-0"
    @ok="createEntity"
  >
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <validation-provider #default="validationContext" name="Building Name">
            <b-form-group label="Building Name" label-for="building-name">
              <b-form-input
                required
                autofocus
                id="building-name"
                placeholder=""
                v-model="buildingCreateData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Campus" label-for="building-campus">
            <v-select
              v-model="buildingCreateData.campus"
              :reduce="(val) => val.value"
              :options="campusOptions"
              :clearable="false"
              transition="smooth"
              input-id="building-campus"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Avatar URL" label-for="building-avatar">
            <b-form-input id="building-avatar" placeholder="" v-model="buildingCreateData.avatar" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Picture URL" label-for="building-picture">
            <b-form-input id="building-picture" placeholder="" v-model="buildingCreateData.pictureUrl" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Status" label-for="building-status">
            <v-select
              v-model="buildingCreateData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              required
              transition="smooth"
              input-id="building-status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Type" label-for="building-type">
            <v-select
              v-model="buildingCreateData.type"
              :reduce="(val) => val.value"
              :options="typeOptions"
              :clearable="false"
              transition="smooth"
              input-id="building-type"
            />
          </b-form-group>
        </b-col>

        <b-row v-if="buildingCreateData.type === 'physical'">
          <b-col cols="12" md="6" lg="12" class="d-flex border-top h-1 mt-50 mb-2"></b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group label="Building Street 1" label-for="building-address-street1">
              <b-form-input
                required
                id="building-address-street1"
                placeholder="123 Main St"
                v-model="buildingCreateData.address.street1"
                :state="buildingCreateData.address.street1 ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building Street 2" label-for="building-address-street2">
              <b-form-input required id="building-address-street2" placeholder="Apt 650" v-model="buildingCreateData.address.street2" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building City" label-for="building-address-city">
              <b-form-input
                required
                id="building-address-city"
                placeholder="Sterling"
                v-model="buildingCreateData.address.city"
                :state="buildingCreateData.address.city ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group
              label="Building State"
              label-for="building-address-state"
              :state="buildingCreateData.address.state ? null : false"
            >
              <v-select
                v-model="buildingCreateData.address.state"
                :options="states"
                :clearable="false"
                required
                transition="smooth"
                input-id="building-address-state"
                class="capped-height"
                placeholder="Virginia"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building Zip Code" label-for="building-address-zip">
              <b-form-input
                required
                id="building-address-zip"
                placeholder="20190"
                v-model="buildingCreateData.address.zip"
                :state="buildingCreateData.address.zip ? null : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormInvalidFeedback,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import siteStoreModule from '@/store/site/siteStoreModule';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useBuilding from '../useBuilding';
import useCampus from '../../campus/useCampus';
import vSelect from 'vue-select';
import states from '@/libs/data/states';

export default {
  name: 'BuildingEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    BModal,
    quillEditor,
    vSelect,
    siteStoreModule,

    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createBuildingActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    '$props.createBuildingActive'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.buildingCreateData = {
        name: null,
        avatar: null,
        pictureUrl: null,
        campus: null,
        type: 'zoom',
        status: 'created',
        address: {
          street1: null,
          street2: null,
          city: null,
          state: '',
          zip: null,
          country: null,
        },
      };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-building-active', false);
    },
    validateForm() {
      if (!this.buildingCreateData.name || !this.buildingCreateData.type) return false;

      if (
        this.buildingCreateData.type === 'physical' &&
        (!this.buildingCreateData.address.street1 ||
          !this.buildingCreateData.address.city ||
          !this.buildingCreateData.address.state ||
          !this.buildingCreateData.address.zip)
      ) {
        delete this.buildingCreateData.address;
        return false;
      }

      return true;
    },
    createEntity(event) {
      this.busy = true;
      event.preventDefault();

      const pl = { ...this.buildingCreateData };

      if (this.validateForm() !== true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Missing Required Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please fill out all required fields.',
          },
        });
        this.busy = false;
        return;
      }

      store
        .dispatch('siteStoreModule/createBuilding', { buildingData: pl })
        .then((response) => {
          this.$emit('update:building-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Building Created!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create building',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    fetchCampuses() {
      store
        .dispatch('siteStoreModule/fetchCampusList')
        .then((response) => {
          this.busy = false;
          this.campusOptions = response.data.results.map((campus) => ({
            label: campus.name,
            value: campus.id,
          }));
        })
        .catch((error) => {
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch campuses',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  data() {
    return {
      states,
      campusOptions: [],
    };
  },
  mounted() {
    this.fetchCampuses();
  },
  setup(props) {
    const buildingCreateData = ref({});
    const busy = ref(false);

    const { refFormObserver, getValidationState, resetForm } = formValidation(buildingCreateData);
    const { statusOptions, typeOptions } = useBuilding();

    return { statusOptions, typeOptions, buildingCreateData, busy, refFormObserver, getValidationState, resetForm };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.vs__dropdown-menu {
  max-height: 250px !important;
}
</style>
