<template>
  <b-modal
    ref="modalBuildingEdit"
    :visible="editBuildingActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Building`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <validation-provider #default="validationContext" name="Building Name" rules="required">
            <b-form-group label="Building Name" label-for="building-name">
              <b-form-input
                required
                autofocus
                id="building-name"
                placeholder=""
                v-model="buildingEditData.name"
                :state="buildingEditData.name ? null : false"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Campus" label-for="building-campus">
            <v-select
              v-model="buildingEditData.campus.id"
              :reduce="(val) => val.value"
              :options="campusOptions"
              :clearable="false"
              transition="smooth"
              input-id="building-campus"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Avatar URL" label-for="building-avatar">
            <b-form-input id="building-avatar" placeholder="" v-model="buildingEditData.avatar" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Picture URL" label-for="building-picture">
            <b-form-input id="building-picture" placeholder="" v-model="buildingEditData.pictureUrl" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Status" label-for="building-status">
            <v-select
              v-model="buildingEditData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              transition="smooth"
              input-id="building-status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Building Type" label-for="building-type">
            <v-select
              v-model="buildingEditData.type"
              :reduce="(val) => val.value"
              :options="typeOptions"
              :clearable="false"
              transition="smooth"
              input-id="building-type"
            />
          </b-form-group>
        </b-col>

        <b-row v-if="buildingEditData.type === 'physical'" class="p-50">
          <b-col cols="12" md="6" lg="12" class="d-flex border-top h-1 mt-50 mb-2"></b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group label="Building Street 1" label-for="building-address-street1">
              <b-form-input
                required
                id="building-address-street1"
                placeholder="123 Main St"
                v-model="buildingEditData.address.street1"
                :state="buildingEditData.address.street1 ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building Street 2" label-for="building-address-street2">
              <b-form-input required id="building-address-street2" placeholder="Apt 650" v-model="buildingEditData.address.street2" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building City" label-for="building-address-city">
              <b-form-input
                required
                id="building-address-city"
                placeholder="Sterling"
                v-model="buildingEditData.address.city"
                :state="buildingEditData.address.city ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group label="Building State" label-for="building-address-state" :state="buildingEditData.address.state ? null : false">
              <v-select
                v-model="buildingEditData.address.state"
                :options="states"
                :clearable="false"
                required
                transition="smooth"
                input-id="building-address-state"
                class="capped-height"
                placeholder="Virginia"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Building Zip Code" label-for="building-address-zip">
              <b-form-input
                required
                id="building-address-zip"
                placeholder="20190"
                v-model="buildingEditData.address.zip"
                :state="buildingEditData.address.zip ? null : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { validateHexColor } from '@/libs/utils/color';
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useBuilding from '../useBuilding';
import useCampus from '../../campus/useCampus';
import vSelect from 'vue-select';
import store from '@/store';
import states from '@/libs/data/states';
import _ from 'lodash';

export default {
  name: 'BuildingEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editBuildingActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    buildingData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      campusOptions: [],
      states,
    };
  },
  watch: {
    '$props.editBuildingActive'(val) {
      this.initModal();
    },
    '$props.buildingData'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.buildingData) {
        const { id, name, campus, avatar, pictureUrl, type, status, address, metadata } = _.cloneDeep(this.$props.buildingData);

        this.buildingEditData = {
          id,
          name,
          campus,
          pictureUrl,
          avatar,
          type,
          status,
          address: {
            street1: address ? address.street1 : null,
            street2: address ? address.street2 : null,
            city: address ? address.city : null,
            state: address ? address.state : null,
            zip: address ? address.zip : null,
            country: address ? address.country : null,
          },
          metadata: {
            design: {
              color: '',
            },
            ...metadata,
          },
        };
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-building-active', false);
    },
    validateForm() {
      if (!this.buildingEditData.name || !this.buildingEditData.type) return false;

      if (
        this.buildingEditData.type === 'physical' &&
        (!this.buildingEditData.address.street1 ||
          !this.buildingEditData.address.city ||
          !this.buildingEditData.address.state ||
          !this.buildingEditData.address.zip)
      ) {
        return false;
      }

      return true;
    },
    saveChanges(event) {
      this.busy = true;

      event.preventDefault();

      if (this.validateForm() !== true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Missing Required Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please fill out all required fields.',
          },
        });
        this.busy = false;
        return;
      }

      const pl = { ...this.buildingEditData };
      pl.campus = pl.campus.id;

      store
        .dispatch('siteStoreModule/updateBuilding', { buildingId: pl.id, buildingData: pl })
        .then((response) => {
          // this.$emit('update:building-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Building Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit building',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    fetchCampuses() {
      store
        .dispatch('siteStoreModule/fetchCampusList')
        .then((response) => {
          this.busy = false;
          this.campusOptions = response.data.results.map((campus) => ({
            label: campus.name,
            value: campus.id,
          }));
        })
        .catch((error) => {
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch campuses',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.fetchCampuses();
  },
  setup(props) {
    const buildingEditData = ref({});
    const busy = ref(false);

    const { refFormObserver, getValidationState, resetForm } = formValidation(buildingEditData);
    const { statusOptions, typeOptions } = useBuilding();
    const { fetchCampusList } = useCampus();

    return { fetchCampusList, statusOptions, typeOptions, buildingEditData, busy, refFormObserver, getValidationState, resetForm };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
